import * as React from "react";
import { Link } from "gatsby";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  let header;

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">
          <span
            style={{
              fontWeight: "var(--fontWeight-normal)",
              letterSpacing: "0",
            }}
          >
            Anna
          </span>
          <span
            style={{
              fontWeight: "var(--fontWeight-normal)",
              letterSpacing: "-0.25em",
            }}
          >
            {" "}
          </span>
          <span
            style={{
              letterSpacing: "0.025em",
            }}
          >
            Rossetti
          </span>
        </Link>
      </h1>
    );
  } else {
    header = (
      <Link
        style={{ color: "var(--color-heading-black)" }}
        className="header-link-home"
        to="/"
      >
        {title}
      </Link>
    );
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()} {title}. All Rights Reserved. Handcrafted
        with ❤️ in England, UK 🇬🇧.
      </footer>
    </div>
  );
};

export default Layout;
